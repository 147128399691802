.table {
  overflow-x: auto;
}

.tableLabel {
  cursor: pointer;
}

.tableWrapper {
  overflow-x: auto;
}

.spaceDetailsContainer {
  min-height: 500px;
}

.activeIcon {
  color: green;
}
.inActiveIcon {
  color: red;
}

.mapIcon {
  color: green;
  cursor: pointer;
}

.infoIcon {
  color: #1c9cdc;
  cursor: pointer;
}

.numbersFilter,
.textFilter {
  input {
    height: 40px;
    text-align: left;
  }
  label {
    height: 40px;
    text-align: left;
  }
}
.filterGridItem {
  padding: 8px 0px;
}
.textFilter {
  margin-left: 10px;
}
.tableRow:nth-child(even) {
  background-color: #e1e6ea;
}

.gridWrap {
  padding: 20px;
  label {
    font-size: 0.8em;
    font-family: "SourceSerifPro", "Georgia";
  }
}

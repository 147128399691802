.gridContainer {
  background: whitesmoke;
  margin: 50px 0px;
}

.formBuilderContainer {
  margin: 0px 0px 200px 0px;
}

.presentationBtnContainer {
  position: absolute;
  top: 110px;
  right: 90px;
  z-index: 999;
  @media screen and (max-width: 437px) {
    top: 150px;
    right: 40px;
  }
}

.printBtn {
  position: absolute;
  right: 25px;
  top: 50px;
  background: black;
  color: white;
  height: 40px;
  line-height: 40px;
  padding: 0px 20px;
  font-family: arial;
  font-size: 17px;
}

.printStyles {
  background: red;
  button {
    display: none;
  }
}


.react-calendar__tile--active {
  background: #2D3E80;
  color: white;
}

.react-calendar__tile--active:hover {
  background: #2D3E80;
  color: white;
}

.react-calendar__tile--now {
  border: 1px solid black;
  background: #2D3E80;
}

.react-calendar {
  opacity: 1.0;
}

.ReactModal__Overlay {
  background: rgba(57,87,113,0.8) !important;
}

.ReactModal__Overlay--after-open {
  z-index: 2000;
}

@import "globals";
@import "./fonts";

html,
body {
  font-family: SourceSerifPro;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  font-family: SourceSerifPro;
  background-attachment: fixed;
}

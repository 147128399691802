.form {
  text-align: left;
  background: whitesmoke;
  padding: 25px;
  input {
    width: 300px;
    max-width: 95%;
  }
  label {
  }
  select {
    width: 300px;
    max-width: 95%;
  }
  div {
    margin: 6px 0px;
    width: 300px;
    max-width: 95%;
  }
  button {
    color: white;
  }
}
.filterField {
  margin-right: 15px;
}

.rightIcon {
  padding-left: 15px;
}

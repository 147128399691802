@media screen {
  .App {
    text-align: center;
  }

  .container {
    margin: 0 auto;
  }

  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
  }

  .App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
  }

  .App-title {
    font-size: 1.5em;
  }

  .App-intro {
    font-size: large;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .firebaseui-id-page-password-sign-up form {
    display: none !important;
  }
  .firebaseui-id-page-password-sign-up:after {
    content: "Tällä sähköpostiosoitteella ei löytynyt käyttäjää. Tarkista sähköpostiosoite tai ole yhteydessä Newsec yhteyshenkilöösi." !important;
  }

  .firebaseui-id-page-password-sign-up {
    padding: 25px;
    font-family: SourceSerifPro, Georgia !important;
  }

  .firebaseui-button {
    background-color: #2D3E80 !important;
  }

  .firebaseui-title, .firebaseui-textfield, .mdl-button {
    font-family: SourceSerifPro, Georgia !important;
  }

}

@media print {
  form {
    all: initial;
    all: unset;
  }

  .Translate-Navigation--root-1 {
    display: none;
  }
  button {
    display: none !important;
  }
}

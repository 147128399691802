.root {
  flex-grow: 1;
}
.flex {
  flex-grow: 1;
}
.menuButton {
  margin-left: -12;
  margin-right: 20;
}

.logo {
  height: 35px;
  @media screen and (max-width: 600px) {
    height: 25px;
    margin-right: 10px;
  }
}

.menuLink {
  color: #999 !important;
  display: "flex";
  align-items: "center";
  justify-content: "center";
  opacity: 1 !important;
}

.menuLinkActive {
  border-bottom: 5px solid #2D3E80 !important;
  color: #2D3E80 !important;
}

.margin {
  margin: 30px;
}

.padding {
  padding: 10px;
}

.notificationTime {
  font-size: 11px;
  color: #666;
  width: 100px;
}

.notificationText {
  font-size: 13px;
  font-weight: 400;
}

.notificationIcon {
  cursor: pointer;
}
.menuActionSection div {
  display: inline;
}
a {
  text-decoration: none;
}
.langIconHolder {
  margin-left: 10px;
  margin-right: 10px;
}

.langLink {
  color: #999 !important;
}

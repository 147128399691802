@font-face {
  font-family: "SourceSerifPro";
  src: url("./assets/SourceSerifPro-Black.woff2") format("woff2"),
    url("./assets/SourceSerifPro-Black.woff") format("woff"),
    url("./assets/SourceSerifPro-Black.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "SourceSerifPro";
  src: url("./assets/SourceSerifPro-Bold.woff2") format("woff2"),
    url("./assets/SourceSerifPro-Bold.woff") format("woff"),
    url("./assets/SourceSerifPro-Bold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SourceSerifPro";
  src: url("./assets/SourceSerifPro-Regular.woff2") format("woff2"),
    url("./assets/SourceSerifPro-Regular.woff") format("woff"),
    url("./assets/SourceSerifPro-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}


.table {
  overflow-x: auto;
}

.tableLabel {
  cursor: pointer;
}

.tableWrapper {
  overflow-x: auto;
}

.spaceDetailsContainer {
  min-height: 500px;
}

.activeIcon {
  color: green;
}
.inActiveIcon {
  color: red;
}

.mapIcon {
  color: green;
  cursor: pointer;
}

.infoIcon {
  color: #1c9cdc;
  cursor: pointer;
}

.tableRow:nth-child(even) {
  background-color: #e1e6ea;
}
